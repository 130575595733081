import { getAddons, getPlanDetail, getPlans } from 'common/planData'
import { TAddon, TFAQList, TPlanDetail, TReviews } from 'common/cms-types'
import { I18NConfig } from 'next/dist/server/config-shared'
import { NextPageWithLayout } from '../types/nextPageWithLayout'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import SeoContainer from 'components/util/containers/SeoContainer'
import { NextSeoProps } from 'next-seo'
import { planColors, planGradients, PlanSlug } from 'common/planSlugs'
import { getApi } from 'common/api-common'
import { virtualOfficeOldStreetBreadcrumb } from 'components/util/containers/Breadcrumbs'
import { planDetailStrings } from 'common/strings/plan-detail-strings'
import PlanCheckout from 'components/PlanCheckout'
import KeyPointsCard from 'components/cards/base/KeyPointsCard'
import RobotPointingCard from 'components/cards/base/RobotPointingCard'
import ProductKeyFeaturesAccordion from 'components/sections/ProductKeyFeaturesAccordion'
import TenMinutesOrLessCard from 'components/cards/TenMinutesOrLessCard'
import TenMinutesOrLessSetupCard from 'components/cards/TenMinutesOrLessSetupCard'
import OurAddressCard from 'components/cards/OurAddressCard'
import { getFAQList, getReviews } from 'common/cms-sdk'
import { createClient } from '../prismicio'
import { Utils } from 'common/utils'
import FAQList from 'components/util/FAQList'
import Strings from 'project/localisation'
import GetInTouchSection from 'components/sections/GetInTouchSection'
import ReviewsSection from 'components/sections/ReviewsSection'
import SecurityAndPrivacySection from 'components/sections/SecurityAndPrivacySection'
import OneTwoThreeCard from 'components/cards/base/OneTwoThreeCard'
import { planToEvent } from 'common/utils/planToEvent'
import PhysicalCopyCard from 'components/cards/PhysicalCopyCard'
import PhoneCardsSection from 'components/sections/PhoneCardsSection'
import useWindowSize from 'components/util/useWindowSize'
import classNames from 'classnames'
import imageResize from 'project/image-proxy-web'
import { image } from '@prismicio/helpers'
import standardiseTitle from 'project/standardiseTitle'
import classnames from 'classnames'

export type PlanPageType = {
  // @ts-ignore
  plan: TPlanDetail | null
  addons: TAddon[] | null
  faqList: TFAQList | null
  reviews: TReviews
}
const PlanPagePage: NextPageWithLayout<PlanPageType> = ({
  addons,
  faqList,
  plan,
  reviews,
}) => {
  const router = useRouter()
  const size = useWindowSize()

  useEffect(() => {
    if (!plan) {
      router.replace('/').then((res) => {
        console.error(`Page not found ${router.asPath}`)
      })
    }
  }, [router, plan])

  useEffect(() => {
    if (plan) {
      getApi().trackEvent(
        'view_item_ga4',
        planToEvent(plan.planSummary, 'month'),
      )
    }
  }, [plan])

  if (!plan) {
    return (
      <div
        className={
          'bg-white position-fixed top-0 bottom-0 start-0 end-0 z-index-99999'
        }
      >
        <div
          className={
            'd-flex justify-content-center align-items-center text-center min-vh-100'
          }
        >
          <div>
            <div
              className='spinner-border spinner-border-lg color-pink5'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const breadcrumb = virtualOfficeOldStreetBreadcrumb().concat([
    {
      item: `https://www.hoxtonmix.com/${plan.slug}/`,
      name: `${plan.name}`,
      position: 3,
    },
  ])

  const seoProps: NextSeoProps = {
    description: `${planDetailStrings[plan.seoDescription]}`,
    openGraph: {
      url: `https://www.hoxtonmix.com/${plan.slug}/`,
    },
    title: standardiseTitle(`${planDetailStrings[plan.seoTitle]}`),
  }
  const color = planColors[plan.slug]
  const gradient = planGradients[plan.slug]
  return (
    <SeoContainer breadcrumb={breadcrumb} seoProps={seoProps}>
      <div className='container-fluid'>
        <PlanCheckout
          key={plan.slug}
          plans={[plan.planSummary]}
          addons={plan.hideAddons ? [] : addons}
          plan={plan}
        />
      </div>
      <main>
        <div className='container'>
          {!!plan.howItWorks && !!plan.prioritiseHowItWorks && (
            <div className='mt-xxxxl-17 mt-0 mb-lg-18'>
              <OneTwoThreeCard
                color={
                  plan.slug === PlanSlug.SCAN_PRO && !size.isLgOrHigher
                    ? 'gradient-blue'
                    : gradient
                }
                title={`${Strings.howItWorks}`}
                content={plan.howItWorks.map((v) => ({
                  content: `${planDetailStrings[v.content]}`,
                  image: v.image,
                  showImage: v.showImage,
                  title: `${planDetailStrings[v.title]}`,
                }))}
                imagesOption={plan.howItWorksOption}
              />
            </div>
          )}
          <div className={classNames('row flex-column-reverse flex-xl-row')}>
            {plan.keyPoints && (
              <KeyPointsCard
                className={classNames(
                  'pt-7 pb-8 px-lg-6 px-4',
                  plan.slug === PlanSlug.FORWARD
                    ? 'py-xxxl-10 ps-xxl-14 pe-xxl-3'
                    : [PlanSlug.SCAN_LITE, PlanSlug.SCAN_PRO].includes(
                        plan.slug,
                      )
                    ? 'ps-xxl-14 pe-xxl-10'
                    : 'py-xxxl-10 px-xxl-14',
                  [PlanSlug.SCAN_LITE].includes(plan.slug) ? 'py-xxxl-10' : '',
                  plan.slug === PlanSlug.SCAN_PRO ? 'py-xxxl-8' : '',
                )}
                padding={'0'}
                color={color}
                items={plan.keyPoints.map((point) => ({
                  body: `${planDetailStrings[point.body]}`,
                  title: `${planDetailStrings[point.title]}`,
                }))}
                paragraphClass={
                  [PlanSlug.SCAN_LITE, PlanSlug.SCAN_PRO].includes(plan.slug)
                    ? 'mb-lg-3 mb-4'
                    : 'mb-xxxl-5 mb-lg-3 mb-6'
                }
              />
            )}
            {!!plan.keyPointSlogan && (
              // @ts-ignore
              <RobotPointingCard
                theme={
                  [PlanSlug.COLLECT, PlanSlug.SCAN_PRO].includes(plan.slug)
                    ? `${gradient}2`
                    : gradient
                }
                hasHmSymbol={plan.hasKeyPointHmSymbol}
                hmSymbolSize={
                  [PlanSlug.SCAN_LITE, PlanSlug.SCAN_PRO].includes(plan.slug)
                    ? 'large'
                    : 'small'
                }
                className={classnames(
                  'overflow-hidden',
                  plan.slug === PlanSlug.FORWARD
                    ? 'px-xl-5 px-4 py-xl-7 pt-4 pb-25'
                    : [PlanSlug.SCAN_LITE, PlanSlug.SCAN_PRO].includes(
                        plan.slug,
                      )
                    ? 'ps-xl-7 pe-xl-4 ps-6 py-lg-6 pt-4 pb-5'
                    : 'px-xl-13 px-8 py-md-6 py-4',
                )}
              >
                {plan.slug === PlanSlug.COLLECT && (
                  <img
                    loading='lazy'
                    alt={`Plugz point down arm icon`}
                    className='img-fluid position-absolute top-0 end-0'
                    src={imageResize(
                      require('public/images/plans/plugz-point-down-arm.png'),
                    )}
                    width={
                      size.isXxlOrHigher
                        ? 425
                        : size.isXxlOrHigher
                        ? 335
                        : !size.isMdOrLower
                        ? 295
                        : 220
                    }
                  />
                )}
                {plan.slug === PlanSlug.FORWARD && (
                  <img
                    loading='lazy'
                    alt={`Plugz point down arm icon`}
                    className='img-fluid position-absolute bottom-0 start-50 translate-middle-x'
                    src={imageResize(
                      require('public/images/plans/forward-page-point-up-hand-desktop.png'),
                    )}
                    width={
                      size.isXxxlOrHigher ? 316 : size.isXlOrHigher ? 255 : 157
                    }
                  />
                )}
                {[PlanSlug.SCAN_LITE, PlanSlug.SCAN_PRO].includes(
                  plan.slug,
                ) && (
                  <img
                    loading='lazy'
                    alt={`Plugz point down arm icon`}
                    className='img-fluid position-absolute top-0 end-0 z-index-1'
                    src={imageResize(
                      require('public/images/plans/scan-pro-page-same-day-hand-point-desktop.png'),
                    )}
                    width={
                      size.isXxxlOrHigher ? 532 : size.isXlOrHigher ? 380 : 264
                    }
                  />
                )}
                <div
                  className={
                    plan.keyPointSloganClass ? plan.keyPointSloganClass : ''
                  }
                  dangerouslySetInnerHTML={{
                    __html: `${planDetailStrings[plan.keyPointSlogan]}`,
                  }}
                />
              </RobotPointingCard>
            )}
          </div>
          {[PlanSlug.SCAN_LITE, PlanSlug.SCAN_PRO].includes(plan.slug) &&
            !!plan.setupString && (
              <div
                className={classNames(
                  'row mt-lg-17 mb-lg-18',
                  plan.slug === PlanSlug.SCAN_LITE ? 'mt-14' : '',
                  ![PlanSlug.SCAN_LITE].includes(plan.slug) ? 'mt-4' : '',
                )}
              >
                <TenMinutesOrLessCard
                  robotHeadCardOption={plan.tenMinutesOrLessCardOption}
                  tenMinutesOrLessCardIsLarge={plan.tenMinutesOrLessCardIsLarge}
                  condensed
                  theme={gradient}
                />
                <TenMinutesOrLessSetupCard
                  title={plan.setupTitle}
                  classesForTitle={''}
                  subtitle={plan.setupSubTitle}
                  subtitleMobileOnly={[PlanSlug.SCAN_LITE].includes(plan.slug)}
                  cards={plan.slug === PlanSlug.SCAN_PRO}
                  content={plan.setupString}
                  theme={color}
                  className={classNames(
                    'px-4 pt-5 pb-6',
                    plan.slug === PlanSlug.SCAN_LITE ? 'px-sm-9' : 'px-sm-2',
                  )}
                />
              </div>
            )}
          {[PlanSlug.SCAN_LITE].includes(plan.slug) && (
            <PhysicalCopyCard className={''} />
          )}
          {!!plan.keyFeaturesTitle && (
            <div
              className={classNames(
                [
                  PlanSlug.FORWARD,
                  PlanSlug.SCAN_LITE,
                  PlanSlug.SCAN_PRO,
                ].includes(plan.slug)
                  ? 'mt-xl-25 mt-lg-12'
                  : 'mt-lg-12',
                'mt-8 mb-lg-16 mb-8',
              )}
            >
              <ProductKeyFeaturesAccordion
                title={`${
                  size.isMdOrLower && plan.keyFeaturesTitleMobile
                    ? planDetailStrings[plan.keyFeaturesTitleMobile]
                    : planDetailStrings[plan.keyFeaturesTitle]
                }`}
                color={color}
                plan={plan}
                imageClasses={
                  [PlanSlug.FORWARD, PlanSlug.SCAN_LITE].includes(plan.slug)
                    ? 'ms-xl-n20 ms-lg-n8 mt-xl-n20'
                    : plan.slug === PlanSlug.SCAN_PRO
                    ? 'ms-xl-6 mt-xl-n20'
                    : 'ms-lg-n8'
                }
              />
            </div>
          )}
          {plan.slug === PlanSlug.PHONE && <PhoneCardsSection />}
          {![PlanSlug.SCAN_LITE, PlanSlug.SCAN_PRO].includes(plan.slug) &&
            !!plan.setupString && (
              <div className='row'>
                <TenMinutesOrLessCard
                  robotHeadCardOption={plan.tenMinutesOrLessCardOption}
                  condensed
                  theme={gradient}
                />
                <TenMinutesOrLessSetupCard
                  content={plan.setupString}
                  theme={color}
                />
              </div>
            )}
          {!plan.hidePrivacy && <SecurityAndPrivacySection />}
          <GetInTouchSection
            title={
              plan.slug === PlanSlug.PHONE
                ? !size.isSmOrLower
                  ? Strings.getInTouchTitleForVirtualOfficePhone
                  : Strings.experiencedTeam
                : Strings.experiencedTeam
            }
            className={
              plan.slug === PlanSlug.PHONE
                ? 'mt-xl-17 mt-9 mb-5'
                : 'mt-xl-17 my-5'
            }
          />
          {!!plan.howItWorks && !plan.prioritiseHowItWorks && (
            <div className='mt-lg-17 mt-20 mb-lg-18'>
              <OneTwoThreeCard
                color={gradient}
                title={`${Strings.gettingYouSetup}`}
                content={plan.howItWorks.map((v) => ({
                  content: `${planDetailStrings[v.content]}`,
                  image: v.image,
                  showImage: v.showImage,
                  title: `${planDetailStrings[v.title]}`,
                }))}
                imagesOption={plan.howItWorksOption}
              />
            </div>
          )}

          {!!faqList && (
            <FAQList
              subtitle={
                size.isSmOrLower
                  ? Strings.faqDescription
                  : faqList.data.subtitle
              }
              title={faqList.data.title}
              slices={faqList.data.slices}
            />
          )}
          <OurAddressCard className={'mt-md-12 mb-md-20 my-8'} />
        </div>
        {plan.slug !== PlanSlug.PHONE && (
          // @ts-ignore
          <ReviewsSection
            theme={
              plan.slug === PlanSlug.SCAN_PRO && !size.isLgOrHigher
                ? 'gradient-blue'
                : [PlanSlug.COLLECT, PlanSlug.SCAN_PRO].includes(plan.slug)
                ? `${gradient}3`
                : gradient
            }
            data={reviews}
          />
        )}
      </main>
    </SeoContainer>
  )
}

export async function getStaticPaths(c: I18NConfig) {
  const products = await getPlans(true)
  const productDetails = await Promise.all(
    products
      .filter((v) => v.slug !== PlanSlug.MEETINGS)
      .map((product) => {
        return getPlanDetail(product.slug)
      }),
  )
  return {
    fallback: 'blocking',
    paths: productDetails
      .filter((product) => {
        return !!product
      })
      .map((product) => {
        return {
          params: { planSlug: product!.slug },
        }
      })
      .flat(),
  }
}
export async function getStaticProps(
  context: I18NConfig & { locale?: string; params: { planSlug: string } },
) {
  try {
    const client = createClient(context)
    const lang = Utils.getLocaleID(context.locale || context.defaultLocale)
    const reviews = await getReviews()

    const plan = (await getPlanDetail(context.params.planSlug)) || null

    let faqList = null
    try {
      faqList = await getFAQList(client, lang, context.params.planSlug)
    } catch (e) {}
    let addons: TAddon[] | null = null
    if (plan) {
      addons = await getAddons(false)
    }
    return {
      props: {
        addons,
        faqList,
        plan: plan.planSummary ? plan : null,
        reviews,
      },
    }
  } catch (e) {
    console.log('ERROR', e)
    return {
      props: {
        plan: null,
      },
    }
  }
}

export default PlanPagePage
