import React, { FC } from 'react'
import { asText } from '@prismicio/helpers'
import { FAQPageJsonLd } from 'next-seo'
import { Slice, SliceZone } from '@prismicio/types'
import { TFaq } from 'common/cms-types'
import Accordion from 'components/Accordion'
import { RichText } from 'components/forms/RichText'
import HeadingAndText from 'components/HeadingAndText'
import Strings from 'project/localisation'
import SocialLinks from 'components/SocialLinks'

type FAQJSONLDType = {
  title?: string
  subtitle?: string
  slices: SliceZone<Slice<'faq', TFaq>>
  styledAsRichTextForBlog?: boolean
  expandAllButton?: boolean
  expandAllButtonDesktopOnly?: boolean
}

const FAQList: FC<FAQJSONLDType> = ({
  expandAllButton = false,
  expandAllButtonDesktopOnly = false,
  slices,
  styledAsRichTextForBlog = false,
  subtitle,
  title = `${Strings.frequentlyAskedQuestions}`,
}) => {
  const faqs = slices?.filter((v) => v.slice_type === 'faq')
  const faqJSONLd = faqs?.map((v) => ({
    acceptedAnswerText: asText(v.primary.answer),
    questionName: v.primary.question,
  }))
  return faqs?.length ? (
    <>
      <FAQPageJsonLd mainEntity={faqJSONLd} />
      {!styledAsRichTextForBlog ? (
        <div className='d-flex justify-content-md-center mt-lg-17 mb-lg-16 my-8'>
          <div className='col-lg-9 col-12 px-md-5'>
            <div className='mb-lg-15 mb-5'>
              <HeadingAndText
                title={title || Strings.frequentlyAskedQuestions}
                subtitle={subtitle || Strings.faqDescription}
                classesForSubTitle={'px-sm-0 px-4'}
              />
            </div>
            <div className={'px-2'}>
              <Accordion
                expandAllButton={expandAllButton}
                expandAllButtonDesktopOnly={expandAllButtonDesktopOnly}
                items={faqs.map((v) => ({
                  body: (
                    <RichText
                      isBlog={false}
                      className='mb-4'
                      text={v.primary.answer}
                    />
                  ),
                  title: v.primary.question,
                }))}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <h2 className={'px-md-11'}>{Strings.faq}</h2>
          {faqs.map((v) => {
            return (
              <>
                <h3 className={'px-md-11'}>{v.primary.question}</h3>
                <RichText isBlog text={v.primary.answer} />
              </>
            )
          })}
          <div className={'px-md-11 mt-md-8 mt-4'}>
            <SocialLinks linkType='share' />
          </div>
        </>
      )}
    </>
  ) : null
}

export default FAQList
