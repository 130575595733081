import React, { FC, KeyboardEventHandler, useEffect } from 'react'
import classNames from 'classnames'
import Icon from './Icon'
import useWindowSize from './util/useWindowSize'

interface Modal {
  isOpen: boolean
  onDismiss: () => void
}

const Modal: FC<Modal> = ({ children, isOpen, onDismiss }) => {
  const size = useWindowSize()
  useEffect(() => {
    // @ts-ignore
    document.addEventListener('keydown', keydownFunction, false)
    return () => {
      // @ts-ignore
      document.removeEventListener('keydown', keydownFunction, false)
    }
    //eslint-disable-next-line
  }, [])
  const keydownFunction: KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      onDismiss()
    }
  }
  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add('modal-open')
    } else if (typeof document !== undefined) {
      document.documentElement.classList.remove('modal-open')
    }
  }, [isOpen])
  return (
    <>
      {isOpen && (
        <>
          <div
            tabIndex={-1}
            role='dialog'
            onClick={onDismiss}
            className={classNames('modal modal-lg fade', {
              'd-block': isOpen,
              'pointer-event-none': !isOpen,
              show: isOpen,
            })}
          >
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-body'>
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  className='modal-content'
                >
                  <div onClick={onDismiss} className={'cursor-pointer'}>
                    <Icon
                      name={'close-circle'}
                      width={size.isSmOrLower ? 32 : 35.943}
                      height={size.isSmOrLower ? 32 : 35.943}
                    />
                  </div>
                  <div className={'row modal-text'}>{children}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={onDismiss}
            className={classNames('modal-backdrop fade', {
              'pointer-event-none': !isOpen,
              show: isOpen,
            })}
          />
        </>
      )}
    </>
  )
}

export default Modal
