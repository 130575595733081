import { FC } from 'react'
import classNames from 'classnames'
import { ColorType } from 'common/types/color-type'

type WhatDoYouGetItemType = {
  title: string
  titleClass?: string
  paragraphClass?: string
  body: string
  color?: ColorType
}
const WhatDoYouGetItem: FC<WhatDoYouGetItemType> = ({
  body,
  color = 'pink4',
  paragraphClass = 'text-medium',
  title,
  titleClass = 'h4',
}) => {
  return (
    <>
      <h3
        className={classNames(`color-${color}`, 'text-semibold', titleClass)}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className={classNames('text-medium', paragraphClass)}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </>
  )
}

export default WhatDoYouGetItem
