import React, { FC } from 'react'
import ImageCard from 'components/cards/base/ImageCard'
import { virtualOfficePhoneStrings } from 'common/strings/virtual-office-phone-strings'
import Strings from 'project/localisation'
import RobotHeadCard from 'components/cards/base/RobotHeadCard'
import Card from 'components/cards/base/Card'
import KeyPoint from 'components/KeyPoint'
import HeadingAndText from 'components/HeadingAndText'
import Accordion from 'components/Accordion'
import useWindowSize from 'components/util/useWindowSize'
import classNames from 'classnames'
import imageResize from 'project/image-proxy-web'

type PhoneCardsSectionType = {}

const PhoneCardsSection: FC<PhoneCardsSectionType> = ({}) => {
  const size = useWindowSize()
  return (
    <>
      <ImageCard
        className='card-with-vertically-centered-image mb-sm-16 mb-40'
        padding={'0'}
        theme='lavender'
        image={require('public/images/phone/phone-page-virtual-phone-desktop.png')}
        imageAlt={`${virtualOfficePhoneStrings.virtualNumberThatTravels} icon`}
        imageClass={'vertically-centered-image max-w-xxxl-md-50'}
        mobileImage={require('public/images/phone/phone-page-virtual-phone-mobile.png')}
        mobileImageProps={{ className: 'vertically-centered-image' }}
        classNameForCol1={
          'col-xxxl-8 col-sm-7 col-11 py-xxxl-21 py-xl-15 py-lg-10 pt-7 pb-22 px-lg-13 px-5'
        }
        classNameForCol2={'col-lg-4'}
      >
        {!size.isLgOrHigher && (
          <>
            <h2 className={'display-2 text-semibold text-white'}>
              {virtualOfficePhoneStrings.virtualOfficeDivert}
            </h2>
            <p className={'display-3 text-semibold text-white'}>
              {virtualOfficePhoneStrings.virtualNumberThatTravels}
            </p>
          </>
        )}
        {size.isLgOrHigher && (
          <h2 className={'display-1--large text-semibold text-white'}>
            {virtualOfficePhoneStrings.virtualNumberThatTravels}
          </h2>
        )}
      </ImageCard>
      <ImageCard
        padding='lg'
        className='virtual-phone-number-travels-card mt-xxl-30 mt-lg-15 mb-lg-18 mb-4 text-center'
        imageLeft
        image={require('public/images/phone/phone-page-travels-with-you-desktop.png')}
        imageAlt={`${virtualOfficePhoneStrings.virtualNumberThatTravels} logo`}
        imageClass={
          'position-lg-absolute bottom-0 left-0 max-w-xxxl-md-50 max-h-vh mx-auto'
        }
        classNameForCol1={'col-lg-6 py-xl-17 py-lg-10 pt-2 pb-6'}
        classNameForCol2={
          'col-lg-6 px-md-7 pt-md-0 pt-5 mt-xl-0 mt-md-n20 mt-n28'
        }
      >
        <p className='lead color-pink2 mb-4'>{Strings.virtualPhone}</p>
        <h2 className={'px-xxxl-0 px-xxl-10'}>
          {virtualOfficePhoneStrings.virtualNumberThatTravels}
        </h2>
        <p className='text-xl text-semibold px-xl-10 px-3 mt-md-0 mt-3'>
          {virtualOfficePhoneStrings.virtualNumberThatTravelsSubtitle}
        </p>
        <p className={'lead px-xxxl-2 px-xxl-4 px-2'}>
          {virtualOfficePhoneStrings.virtualNumberThatTravelsDescription}
        </p>
      </ImageCard>
      <div className='row'>
        <RobotHeadCard
          theme='lavender'
          titleClass={'phone-plan-card-text'}
          className={'pt-xxxl-9 pt-5'}
          robotHeadScale
        >
          {virtualOfficePhoneStrings.neverMissAnImportantCall}
        </RobotHeadCard>

        <Card className='col-lg-6 text-center py-xxxl-13 py-lg-10 pt-8 pb-5 px-lg-5 px-6'>
          <h2
            className='color-lavender mb-4'
            dangerouslySetInnerHTML={{
              __html: virtualOfficePhoneStrings.lookProfessional,
            }}
          />
          <div
            className={'lead'}
            dangerouslySetInnerHTML={{
              __html: virtualOfficePhoneStrings.lookProfessionalDescription,
            }}
          />
        </Card>
      </div>
      <ImageCard
        padding='0'
        className='mt-lg-20 mt-26'
        imageLeft={!size.isLgOrHigher}
        textAlign='center'
        image={require('public/images/phone/phone-page-how-it-works-desktop.png')}
        imageAlt={`${virtualOfficePhoneStrings.virtualNumberThatTravels} logo`}
        imageClass={
          'position-lg-absolute bottom-0 top-0 end-0 max-w-xxxl-md-50 m-auto'
        }
        classNameForCol1={'col-lg-6 ps-lg-10 pe-lg-0 py-lg-14 pb-8'}
        classNameForCol2={'col-lg-6 px-0 mt-lg-auto mt-n18'}
      >
        <div className={'ps-xxl-10 pe-xxl-3 px-lg-0 px-6'}>
          <h2 className='mb-4'>{Strings.howItWorks}</h2>
          <KeyPoint
            color='lavender'
            title={
              size.isMdOrLower
                ? virtualOfficePhoneStrings.virtualOfficeDivert
                : virtualOfficePhoneStrings.setup
            }
            titleClass={size.isMdOrLower ? 'text-medium mb-4' : 'h4'}
            paragraphClass={size.isMdOrLower ? 'px-4' : ''}
            body={virtualOfficePhoneStrings.setupDescription}
          />

          <KeyPoint
            color='lavender'
            title={virtualOfficePhoneStrings.virtualOfficeForwardingCosts}
            titleClass={size.isMdOrLower ? 'text-medium mb-4' : 'h4'}
            paragraphClass={size.isMdOrLower ? 'px-1' : ''}
            body={
              virtualOfficePhoneStrings.virtualOfficeForwardingCostsDescription
            }
          />
        </div>

        <div className={'ps-xxl-7 pe-xxl-0 px-6'}>
          <p
            className={classNames(
              'lead mt-3',
              size.isMdOrLower ? 'text-semibold' : '',
            )}
          >
            {virtualOfficePhoneStrings.creditCardDescription}
          </p>
          <img
            loading='lazy'
            className='img-fluid d-block mx-auto'
            alt={'Hoxton Mix Symbol'}
            src={imageResize(require('public/images/credit-cards.svg'))}
          />
        </div>
      </ImageCard>
      <div className={'mt-lg-18 mt-8 mb-lg-16 mb-7'}>
        <HeadingAndText
          title={virtualOfficePhoneStrings.costsAndDestination}
          subtitle={virtualOfficePhoneStrings.costsAndDestinationSubtitle}
        />
        <div className='col-lg-9 col-12 mt-lg-15 mt-4 px-lg-5 px-2 mx-auto'>
          <Accordion
            titleForItems={virtualOfficePhoneStrings.destination}
            appendColor='lavender'
            expandAllButton
            items={[
              {
                body: virtualOfficePhoneStrings.ukLandlineDescription,
                title: virtualOfficePhoneStrings.ukLandline,
                titleAppend: virtualOfficePhoneStrings.ukLandlineCost,
              },
              {
                body: virtualOfficePhoneStrings.ukMobileDescription,
                title: virtualOfficePhoneStrings.ukMobile,
                titleAppend: virtualOfficePhoneStrings.ukMobileCost,
              },
              {
                body: virtualOfficePhoneStrings.ukPremiumDescription,
                title: virtualOfficePhoneStrings.ukPremium,
                titleAppend: virtualOfficePhoneStrings.ukPremiumCost,
              },
              {
                body: virtualOfficePhoneStrings.ukPersonalDescription,
                title: virtualOfficePhoneStrings.ukPersonal,
                titleAppend: virtualOfficePhoneStrings.ukPersonalCost,
              },
              {
                body: virtualOfficePhoneStrings.ukSpecialServicesDescription,
                title: virtualOfficePhoneStrings.ukSpecialServices,
                titleAppend: virtualOfficePhoneStrings.ukSpecialServicesCost,
              },
              {
                body: virtualOfficePhoneStrings.ukSpecialServicesOtherDescription,
                title: virtualOfficePhoneStrings.ukSpecialServicesOther,
                titleAppend:
                  virtualOfficePhoneStrings.ukSpecialServicesOtherCost,
              },
              {
                body: virtualOfficePhoneStrings.ukMobileOtherDescription,
                title: virtualOfficePhoneStrings.ukMobileOther,
                titleAppend: virtualOfficePhoneStrings.ukMobileOtherCost,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default PhoneCardsSection
