import React from 'react'
import { ReactFC } from 'common/types/ReactFC'
import { tenMinutesOrLessStrings } from 'common/strings/ten-minutes-or-less-strings'
import { ColorType } from 'common/types/color-type'
import Card from './base/Card'
import classNames from 'classnames'
import useWindowSize from 'components/util/useWindowSize'
import { virtualOfficePhoneStrings } from 'common/strings/virtual-office-phone-strings'
import imageResize from 'project/image-proxy-web'

type TenMinutesOrLessSetupCardType = {
  theme?: ColorType
  title?: keyof typeof tenMinutesOrLessStrings
  classesForTitle?: string
  subtitle?: keyof typeof tenMinutesOrLessStrings
  subtitleMobileOnly?: boolean
  content: keyof typeof tenMinutesOrLessStrings
  cards?: boolean
  className?: string
}

const TenMinutesOrLessSetupCard: ReactFC<TenMinutesOrLessSetupCardType> = ({
  cards = false,
  className = 'px-sm-6 px-4 pt-5 pb-6',
  classesForTitle,
  content,
  subtitle,
  subtitleMobileOnly = true,
  theme,
  title = 'tenMinsOrLess',
}) => {
  const size = useWindowSize()
  return (
    <div className='col-lg-6 mb-lg-0 mb-4'>
      <Card
        padding='0'
        className={classNames(
          'mb-4 text-center justify-content-center',
          className,
        )}
      >
        <h3
          className={classNames(
            'text-semibold h2 mb-lg-3 mb-4',
            `color-${theme}`,
            classesForTitle,
          )}
          dangerouslySetInnerHTML={{
            __html: `${tenMinutesOrLessStrings[title]}`,
          }}
        />
        {subtitle &&
          (!subtitleMobileOnly ||
            (subtitleMobileOnly && !size.isLgOrHigher)) && (
            <p className={'lead-large text-semibold mt-lg-3 mb-4'}>
              {tenMinutesOrLessStrings[subtitle]}
            </p>
          )}
        <section
          className='lead'
          dangerouslySetInnerHTML={{
            __html: `${tenMinutesOrLessStrings[content]}`,
          }}
        />
        {cards && size.isLgOrHigher && (
          <>
            <p
              className={classNames(
                'lead mt-3 mb-6',
                size.isMdOrLower ? 'text-semibold' : '',
              )}
            >
              {virtualOfficePhoneStrings.creditCardDescription}
            </p>
            <img
              loading='lazy'
              className='img-fluid d-block mx-auto'
              alt={'Hoxton Mix Symbol'}
              src={imageResize(require('public/images/credit-cards.svg'))}
            />
          </>
        )}
      </Card>
    </div>
  )
}

export default TenMinutesOrLessSetupCard
