import { PropsWithChildren } from 'react'
import Card, { CardType } from './Card'
import { ReactFC } from 'common/types/ReactFC'
import imageResize from 'project/image-proxy-web'

type LargeIconCardType = CardType & {
  iconImageSource: string
  title: string
  content: string
  padding?: string
}

const LargeIconCard: ReactFC<PropsWithChildren<LargeIconCardType>> = ({
  children,
  content,
  iconImageSource,
  padding = '0',
  title,
  ...rest
}) => {
  return (
    <div className='col-lg-6 mb-4 text-center'>
      <Card {...rest} padding={padding}>
        <div className='text-center mb-2'>
          <img
            loading='lazy'
            alt={`${title} icon`}
            src={imageResize(iconImageSource)}
          />
        </div>
        <p className='lead-large text-bold mb-2'>{title}</p>
        <p
          className={'lead mb-0'}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Card>
    </div>
  )
}

export default LargeIconCard
