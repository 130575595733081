import React, { FC, useState } from 'react'
import Strings from 'project/localisation'
import PriceCard from './plans/PriceCard'
import { TAddon, TDuration, TPlanDetail, TPlanSummary } from 'common/cms-types'
import { Format, Utils } from 'common/utils'
import { planColors } from 'common/planSlugs'
import { planDetailStrings } from 'common/strings/plan-detail-strings'
import useShoppingBag from 'common/useShoppingBag'
import { getAddonsForPlan } from 'common/getAddonsForPlan'
import useCalculatePrice from 'common/useCalculatePrice'
import classNames from 'classnames'
import Card from './cards/base/Card'
import { paymentStrings } from 'common/strings/payment-strings'
import Button from './forms/Button'
import Addon from './plans/Addon'
import { useRouter } from 'next/router'
import SupportText from './SupportText'
import Icon from './Icon'
import useWindowSize from './util/useWindowSize'
import ResponsiveImage from './ResponsiveImage'
import PlanHero from './hero/PlanHero'
import { getPlanImages } from 'project/getPlanImages'
import StickyBar from './StickyBar'
import { getApi } from 'common/api-common'
import { planToEvent } from 'common/utils/planToEvent'
import imageResize from 'project/image-proxy-web'

type PlanCheckoutType = {
  plan: TPlanDetail
  addons: TAddon[]
  plans: TPlanSummary[]
}

const PlanCheckout: FC<PlanCheckoutType> = ({
  addons: _allAddons,
  plan,
  plans,
}) => {
  const [duration, setDuration] = useState<TDuration>('month')
  const router = useRouter()
  const color = planColors[plan.slug]
  const monthsFree = Utils.getMonthsFree(plan.planSummary)
  const savings = monthsFree * plan.planSummary.pricing.month_price
  const feesTitle = plan.feesTitle ? planDetailStrings[plan.feesTitle] : ''
  const fees = plan.fees.map((v) => `${planDetailStrings[v]}`)
  const fees2 = plan.fees2
    ? plan.fees2.map((v) => `${planDetailStrings[v]}`).join(' - ')
    : ''
  const { addPlan, addPlanAddon, removePlanAddon, value } = useShoppingBag()
  const { addons } = value
  const allAddons = getAddonsForPlan(plan?.slug, _allAddons)

  const { addonPrice, originalVAT, planPrice } = useCalculatePrice(
    plan.planSummary,
    allAddons,
    duration,
    addons,
  )
  const size = useWindowSize()
  return (
    <>
      <StickyBar title={Strings[plan.planSummary.name]}>
        <span className='h4 h4--mobile-small text-semibold'>
          {Format.money(
            duration === 'month'
              ? plan.planSummary.pricing.month_price
              : plan.planSummary.pricing.year_price,
          )}
        </span>
        &nbsp;
        <span className='text-small-m text-small-m--mobile-small text-semibold'>
          {duration == 'month'
            ? `${Strings.perMonthPlusVAT}. ${planDetailStrings.flexibleMonthToMonthTerm}`
            : `${Strings.perYearPlusVAT}. ${planDetailStrings.twelveMonthsForPriceOfTenMonths}`}
        </span>
      </StickyBar>
      <div className='row justify-content-md-center mt-md-5 mt-3 pb-xxxl-36 pb-xl-20 pb-9'>
        <div className='col-xl-7 pe-xxxl-0 pe-xxl-11 px-xl-3'>
          <PlanHero
            planName={plan.name}
            price={
              duration === 'month'
                ? plan.planSummary.pricing.month_price
                : plan.planSummary.pricing.year_price
            }
            image={getPlanImages(plan.slug)}
            title={Strings[plan.planSummary.name]}
            subtitle={planDetailStrings[plan.hero]}
            theme='light'
            color={color}
            duration={duration}
          />
        </div>
        <div className='col-xl-4 ps-xxxl-11'>
          <div className={'plan-checkout'}>
            {size.isXlOrHigher &&
              fees.map((fee) => (
                <p
                  key={fee}
                  className={classNames(
                    `color-${color}`,
                    'lead text-semibold text-right mb-1',
                  )}
                >
                  {fee}
                </p>
              ))}
            <div className={'text-lg-start text-center'}>
              <p
                className={classNames(
                  'lead lead-large-mobile text-bold mb-1',
                  'mt-5',
                )}
              >
                {Strings.buy} {Strings[plan.planSummary.name]}
              </p>
              {duration === 'month' ? (
                <p className='text-medium text-medium--lt1 text-medium--mobile-small d-block'>
                  {!!monthsFree &&
                    monthsFree % 2 === 0 &&
                    Strings.formatString(
                      `${paymentStrings.payAnnuallyDescription}`,
                      monthsFree,
                    )}{' '}
                  <span className={'lead lead-extrasmall-mobile text-bold'}>
                    {Strings.formatString(
                      `${paymentStrings.thatsASaving}`,
                      `${Format.money(savings)}`,
                    )}
                  </span>
                </p>
              ) : (
                <p className='d-block lead text-bold'>
                  {Strings.formatString(
                    `${paymentStrings.youJustSaved}`,
                    `${Format.money(savings)}`,
                  )}
                </p>
              )}
            </div>
            <div className='row my-3'>
              <PriceCard
                duration={'month'}
                amount={plan.planSummary.pricing.month_price}
                isActive={duration === 'month'}
                onClick={() => {
                  if (duration !== 'month') {
                    setDuration('month')
                    getApi().trackEvent(
                      'view_item_ga4',
                      planToEvent(plan.planSummary, 'month'),
                    )
                  }
                }}
              >
                {fees?.[0]}
              </PriceCard>
              <PriceCard
                monthsFree={monthsFree}
                duration={'year'}
                amount={plan.planSummary.pricing.year_price}
                isActive={duration === 'year'}
                onClick={() => {
                  if (duration !== 'year') {
                    setDuration('year')
                    getApi().trackEvent(
                      'view_item_ga4',
                      planToEvent(plan.planSummary, 'year'),
                    )
                  }
                }}
              >
                {monthsFree % 2 === 0 &&
                  paymentStrings.formatString(
                    `${paymentStrings.monthsForThePriceOf}`,
                    12 - monthsFree,
                  )}
              </PriceCard>
            </div>
            {feesTitle && fees2 && (
              <div
                className={
                  'text-medium text-medium--mobile-small text-lg-start text-center'
                }
              >
                <p className={'text-semibold mb-1'}>{feesTitle}</p>
                <p
                  className={classNames(
                    `color-${color}`,
                    'text-bold mb-3 ps-lg-0 pe-lg-5 px-5',
                  )}
                >
                  {fees2}
                </p>
              </div>
            )}
            <Card padding='sm' radius='md' theme={'grey3'}>
              <div
                className={'d-flex justify-content-between align-items-center'}
              >
                <p className={'lead lead-large-mobile text-bold mb-0 pe-5'}>
                  {Strings[plan.planSummary.name]}{' '}
                  {duration === 'month'
                    ? paymentStrings.monthly
                    : paymentStrings.annualSaver}
                </p>
                <div>
                  <p
                    className={classNames(
                      'text-xxxl mb-0',
                      size.isMdOrLower ? 'text-semibold' : 'text-bold',
                    )}
                  >
                    {Format.money(
                      duration === 'month'
                        ? plan.planSummary.pricing.month_price
                        : plan.planSummary.pricing.year_price,
                    )}
                  </p>
                  <p className={'text-small text-right mb-0'}>
                    {duration === 'month' ? Strings.perMonth : Strings.perYear}
                  </p>
                </div>
              </div>
              <hr className={'mt-3 mb-4'} />
              {!!_allAddons.length && (
                <div>
                  <p className={'text-medium text-medium--lt1 text-bold mb-0'}>
                    {paymentStrings.peopleWhoBought}
                  </p>
                  <p>{paymentStrings.peopleWhoBoughtDescription}</p>
                  {_allAddons.map((addon: TAddon, i) => (
                    <Addon
                      key={addon.slug}
                      num={i + 1}
                      duration={duration}
                      addon={addon}
                      isInBasket={addons.includes(addon.slug)}
                      onBuyClick={() => {
                        if (addons.includes(addon.slug)) {
                          removePlanAddon(addon.slug, addon)
                        } else {
                          addPlanAddon(addon.slug, addon)
                        }
                      }}
                    />
                  ))}
                  <hr className={'my-lg-4 mt-n2 mb-4'} />
                </div>
              )}
              <div className={'text-right mb-3'}>
                <p className={'text-small-m text-small-m--mobile-small mb-0'}>
                  {paymentStrings.formatString(
                    paymentStrings.vatAt,
                    <strong>{Format.money(originalVAT)}</strong>,
                  )}
                </p>
                <p
                  className={classNames(
                    'h3',
                    size.isSmOrLower ? 'text-semibold' : '',
                  )}
                >
                  {Format.money(planPrice + addonPrice + originalVAT)}
                </p>
              </div>
              <Button
                data-test={`buy-${plan.slug}-button`}
                onClick={() => {
                  addPlan(plan.slug, duration, plan.planSummary)
                  router.push('/checkout')
                }}
                size={'large'}
                className={'text-uppercase py-1'}
              >
                {Strings.buy}
              </Button>
            </Card>
            <div className={'mt-lg-5 mt-8 mb-4'}>
              <SupportText name={plan.planSummary.name} />
            </div>
            <img
              className='img-fluid d-block mx-auto'
              alt={'Hoxton Mix Symbol'}
              src={imageResize(require('public/images/credit-cards.svg'))}
            />
            <div
              className={
                'text-small-m text-semibold d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center text-lg-start text-center mt-4 px-2'
              }
            >
              {paymentStrings.thisIsSecure}
              <div className={'ms-2'}>
                <Icon
                  name='padlock'
                  height={22}
                  width={22}
                  style={{
                    marginRight: 4,
                    marginTop: -4,
                  }}
                />
              </div>
            </div>
            <div className={'d-flex justify-content-around mt-lg-9 mt-2'}>
              <ResponsiveImage
                className='img-fluid'
                alt={'Hoxton Mix Symbol'}
                src={require('public/images/ratings/reviews-logo.svg')}
                mobileSrc={require('public/images/ratings/reviews-logo.svg')}
                mobileProps={{ width: 125 }}
              />
              <ResponsiveImage
                className='img-fluid'
                alt={'Hoxton Mix Symbol'}
                src={require('public/images/ratings/google-logo.svg')}
                mobileSrc={require('public/images/ratings/google-logo.svg')}
                mobileProps={{ width: 95 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlanCheckout
