import React, { FC, useState } from 'react'
import { TAddon, TDuration } from 'common/cms-types'
import Button from 'components/forms/Button'
import Strings from 'project/localisation'
import { Format } from 'common/utils'
import Duration from 'common/utils/Duration'
import Icon from 'components/Icon'
import { planSummaryStrings } from 'common/strings/plan-summary-strings'
import useWindowSize from 'components/util/useWindowSize'
import classNames from 'classnames'
import ModalDefault from 'components/Modal'
import imageResize from 'project/image-proxy-web'

type AddonType = {
  addon: TAddon
  isInBasket: boolean
  duration: TDuration
  onBuyClick: () => void
  num?: number
}

const Addon: FC<AddonType> = ({
  addon,
  duration,
  isInBasket,
  num,
  onBuyClick,
}) => {
  const [termsOpen, setTermsOpen] = useState(false)
  const toggleTerms = () => {
    setTermsOpen(!termsOpen)
  }
  const size = useWindowSize()
  return (
    <>
      <div className={'d-flex justify-content-between my-lg-3 my-4'}>
        <div className={classNames('d-flex')}>
          <div>
            <Icon fill={'black'} name={addon.icon} />
          </div>
          <div className={'ps-2'}>
            <p className={'text-medium text-medium--lt1 pe-sm-2 pe-10 mb-0'}>
              <strong>
                {planSummaryStrings[addon.title]}{' '}
                <span className={'cursor-pointer'}>
                  <Icon onClick={toggleTerms} fill={'black'} name={'info'} />
                </span>
              </strong>
            </p>
            <p
              className={classNames('text-xs')}
              dangerouslySetInnerHTML={{
                __html: `${planSummaryStrings[addon.description]}`,
              }}
            />
          </div>
        </div>
        <div className={'text-end'}>
          <p
            className={classNames(
              'text-xxl mb-0',
              size.isMdOrLower ? 'text-semibold' : 'text-bold',
            )}
          >
            {Format.money(
              duration === 'month'
                ? addon.pricing.month_price
                : addon.pricing.year_price,
            )}
          </p>
          <div className={'text-xs'}>
            <Duration>{duration}</Duration>
          </div>
          <Button
            onClick={onBuyClick}
            theme={isInBasket ? 'text' : 'primary'}
            size='small'
            className={classNames(
              'text-xs mt-md-0 mt-2',
              isInBasket ? 'px-1' : 'px-3',
            )}
          >
            {isInBasket ? Strings.remove : Strings.add}
          </Button>
        </div>
      </div>
      <ModalDefault isOpen={termsOpen} onDismiss={() => setTermsOpen(false)}>
        <div className={'col-lg-7'}>
          <h3
            className={classNames(
              'h4--small d-flex align-items-center',
              addon.modalTitleColor,
            )}
          >
            <Icon fill={'black'} name={addon.icon} className={'me-2'} />
            {planSummaryStrings[addon.modalTitle]}
          </h3>
          <div
            dangerouslySetInnerHTML={{
              __html: `${planSummaryStrings[addon.modalSubtitle]}`,
            }}
          />
          {!size.isLgOrHigher && (
            <img
              loading='lazy'
              // @ts-ignore
              className={`img-fluid d-block modal-image${num}`}
              // @ts-ignore
              src={imageResize(addon.modalImage)}
              alt={`${addon.title}`}
            />
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: `${planSummaryStrings[addon.modalContent]} `,
            }}
          />
          <Button
            onClick={onBuyClick}
            theme={isInBasket ? 'text' : 'primary'}
            className={'modal-btn text-xs py-2'}
          >
            {isInBasket ? Strings.remove : Strings.add}
          </Button>
        </div>
        {size.isLgOrHigher && (
          <div className={'col-lg-5 px-0 d-flex align-items-center'}>
            <img
              loading='lazy'
              // @ts-ignore
              className={`img-fluid modal-image${num}`}
              // @ts-ignore
              src={imageResize(addon.modalImage)}
              alt={`${addon.title}`}
            />
          </div>
        )}
      </ModalDefault>
    </>
  )
}

export default Addon
