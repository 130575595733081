import classNames from 'classnames'
import { ReactFC } from 'common/types/ReactFC'
import Card, { CardType } from './base/Card' // we need this to make JSX compile

export type CardSelectType = CardType & {
  isActive?: boolean
}

const CardSelect: ReactFC<CardSelectType> = ({
  children,
  isActive,
  onClick,
}) => {
  return (
    <Card
      onClick={onClick}
      className={classNames('card-select cursor-pointer', {
        'card-select--active': isActive,
      })}
      theme={'white'}
    >
      {children}
    </Card>
  )
}

export default CardSelect
