import { PrismicRichText } from '@prismicio/react'
import { FC, ReactNode } from 'react'
import { FilledImageFieldImage, RichTextField } from '@prismicio/types'
import classnames from 'classnames'
import classNames from 'classnames'
import Link from 'next/link'

type RichTextType = {
  text: RichTextField
  stripParagraphs?: boolean
  className?: string
  isBlog?: boolean
  urlToShare?: string
  shareTitle?: string
}
type ComponentProps = {
  children: ReactNode
}

export const slugifyHeading = (text: string) => {
  if (!text) return ''
  return text
    .toLowerCase() // Make lowercase
    .replace(/[^\w\s-]/g, '') // Remove all non-word chars
    .replace(/[\s_-]+/g, '-') // Replace spaces and hyphens with a single hyphen
    .replace(/(^-|-$)/g, '') // Remove leading and trailing hyphens
}

const TocNavElement = ({ children, level, node, v }) => {
  const id = slugifyHeading(node.text)
  const res = (
    <div
      className={classNames('mb-1 text-small transition-colors', {
        'pl-2': level === 1,
        'pl-4': level === 2,
      })}
    >
      <a className='block text-slate-700' href={`#${id}`}>
        {node.text}
      </a>
    </div>
  )
  v.count += 1
  return res
}

export const tableOfContentsComponents = ({}: ComponentProps) => {
  const v = { count: 1 }
  return {
    em: () => <></>,
    embed: () => <></>,
    heading1: ({ children, key, node }) => (
      <TocNavElement
        v={v}
        node={node}
        children={children}
        key={key}
        level={1}
      />
    ),
    heading2: ({ children, key, node }) => (
      <TocNavElement
        v={v}
        node={node}
        children={children}
        key={key}
        level={1}
      />
    ),
    heading3: ({ children, key, node }) => (
      <TocNavElement
        v={v}
        node={node}
        children={children}
        key={key}
        level={1}
      />
    ),
    heading4: ({ children, key, node }) => (
      <TocNavElement
        v={v}
        node={node}
        children={children}
        key={key}
        level={1}
      />
    ),
    hyperlink: () => <></>,
    image: () => <></>,
    list: () => <></>,
    listItem: () => <></>,
    oList: () => <></>,
    oListItem: () => <></>,
    paragraph: () => <></>,
    preformatted: () => <></>,
    strong: () => <></>,
  }
}

const components = (
  isBlog?: boolean,
  stripParagraphs?: boolean,
  className?: string,
) => ({
  heading2: ({ children, node }: ComponentProps) => {
    return (
      <h2
        id={slugifyHeading(node.text)}
        className={classNames(className, { 'px-md-11': isBlog })}
      >
        {children}
      </h2>
    )
  },
  heading3: ({ children, node }: ComponentProps) => (
    <h3
      id={slugifyHeading(node.text)}
      className={classNames(className, { 'px-md-11': isBlog })}
    >
      {children}
    </h3>
  ),
  heading4: ({ children, node }: ComponentProps) => (
    <h4
      id={slugifyHeading(node.text)}
      className={classNames(className, { 'px-md-11': isBlog })}
    >
      {children}
    </h4>
  ),
  heading5: ({ children, node }: ComponentProps) => (
    <h5
      id={slugifyHeading(node.text)}
      className={classNames(className, { 'px-md-11': isBlog })}
    >
      {children}
    </h5>
  ),
  hyperlink: (props) => {
    if (!props.node.data.url) {
      return <span>{props.children}</span>
    }
    if (props.node.data.url.includes('https://www.hoxtonmix.com')) {
      let url = props.node.data.url.split('https://www.hoxtonmix.com')[1]
      if (!url.endsWith('/')) {
        url += '/'

        return <Link href={url}>{props.text}</Link>
      }
    } else if (props.node.data.url.includes('https://hoxtonmix.com')) {
      let url = props.node.data.url.split('https://hoxtonmix.com')[1]
      if (!url.endsWith('/')) {
        url += '/'

        return <Link href={url}>{props.text}</Link>
      }
    }
    return (
      <a target='_blank' href={props.node.data.url} rel='noreferrer'>
        {props.children}
      </a>
    )
  },
  image: ({
    node: {
      alt,
      dimensions: { height, width },
      url,
    },
  }: ComponentProps & { node: FilledImageFieldImage }) => {
    return (
      <img
        className={classNames(className, {
          'article-image mb-md-12 mb-5 mt-md-7 mt-5': isBlog,
        })}
        src={url}
        alt={`${alt}`}
        width={width}
        height={height}
      />
    )
  },
  link: ({ children }: ComponentProps) => (
    <strong className='font-bold'>{children}</strong>
  ),
  list: ({ children }: ComponentProps) => (
    <ul className={classNames(className, { 'ps-md-16 pe-md-11': isBlog })}>
      {children}
    </ul>
  ),
  listItem: ({ children }: ComponentProps) => (
    <li className='list-disc'>{children}</li>
  ),
  oList: ({ children }: ComponentProps) => (
    <ol className={classNames(className, { 'ps-md-16 pe-md-11': isBlog })}>
      {children}
    </ol>
  ),
  oListItem: ({ children }: ComponentProps) => (
    <li className='list-decimal'>{children}</li>
  ),
  paragraph: ({ children }: ComponentProps) =>
    stripParagraphs ? (
      <>{children}</>
    ) : (
      <p className={classnames(className, { 'px-md-11': isBlog })}>
        {children}
      </p>
    ),
  strong: ({ children }: ComponentProps) => (
    <strong className='font-bold'>{children}</strong>
  ),
})

export const TableOfContents: FC<RichTextType> = (props) => {
  return (
    <PrismicRichText
      field={props.text}
      components={tableOfContentsComponents(
        props.isBlog,
        props.stripParagraphs,
        props.className,
      )}
    />
  )
}

export const RichText: FC<RichTextType> = (props) => {
  return (
    <PrismicRichText
      field={props.text}
      components={components(
        props.isBlog,
        props.stripParagraphs,
        props.className,
      )}
    />
  )
}
