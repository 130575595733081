import { ReactFC } from 'common/types/ReactFC'
import React from 'react'
import classNames from 'classnames'
import { GradientType } from 'common/types/color-type'
import Card from './Card'
import useWindowSize from 'components/util/useWindowSize'
import imageResize from 'project/image-proxy-web'

type OneTwoThreeCardType = {
  headingClasses?: string
  title: string
  color: GradientType
  content: {
    image: string
    title: string
    content: string
    showImage?: boolean
  }[]
  imagesOption?: 1 | 2 | 3 | 4 | 5
}

const OneTwoThreeCard: ReactFC<OneTwoThreeCardType> = ({
  color,
  content,
  headingClasses,
  imagesOption = 2,
  title,
}) => {
  switch (imagesOption) {
    case 2:
      require('public/images/steps/scan-lite-page-we-deliver-desktop.png')
      require('public/images/steps/scan-lite-page-we-get-your-post-desktop.png')
      require('public/images/steps/scan-lite-page-we-scan-digitise-your-post-desktop.png')
      break
    case 3:
      require('public/images/steps/scan-pro-page-we-get-your-post-desktop.png')
      require('public/images/steps/scan-pro-page-we-digitise-desktop.png')
      require('public/images/steps/scan-pro-page-we-deliver-desktop.png')
      break
  }
  const size = useWindowSize()
  return (
    <>
      <div className={headingClasses}>
        <h3
          className={classNames('h2 text-center text-semibold mb-0', {
            'd-lg-block d-none': imagesOption === 5,
          })}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className={'text-center mt-lg-0 mt-4'}>
          <h3 className={classNames('step-card__large-text', `text-${color}`)}>
            <span>1</span>
            <span>-</span>
            <span>2</span>
            <span>-</span>
            <span>3</span>
          </h3>
        </div>
      </div>
      <div className='row step-card__container mt-10'>
        {content.map((item, i) => (
          <div
            key={item.title}
            className='col-xl-4 text-center step-card__item'
          >
            <Card className='step-card align-items-center'>
              <img
                loading='lazy'
                className={classNames(
                  `img-fluid step-card__image--option${imagesOption} step-card__image${
                    i + 1
                  }`,
                  imagesOption !== 3 ?? 'img-mobile-fullwidth',
                )}
                src={imageResize(item.image)}
                alt={`Step ${i + 1} icon`}
              />

              <div className='step-card__text'>
                <h3
                  className='h3 text-semibold mb-4 mb-lg-2'
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <div
                  className={classNames(
                    'text-medium text-medium--lt2 mb-0',
                    imagesOption === 3 && i === 2 ? 'px-xl-3' : 'px-xl-5',
                  )}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                {item.showImage && size.isXlOrHigher && (
                  <img
                    loading='lazy'
                    className='image-fluid w-full h-auto px-3'
                    src={imageResize(
                      require('public/images/address/homepage-plugz-point-mobile.png'),
                    )}
                    alt={'Plugz point'}
                  />
                )}
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  )
}

export default OneTwoThreeCard
