import { TAddon } from './cms-types'
import { addonToProductMapping, hideAddonsMapping, PlanSlug } from './planSlugs'

export function getAddonsForPlan(
  plan: PlanSlug | null | undefined,
  addons: TAddon[] | null,
) {
  if (!plan) {
    return null
  }
  return addons?.filter((addon) => {
    if (addon.isService) {
      return true
    }
    if (hideAddonsMapping[plan]) {
      return false
    }
    return addonToProductMapping[plan] !== addon.slug
  })
}
