import CardSelect from 'components/cards/CardSelect'
import { Format } from 'common/utils'
import { ReactFC } from 'common/types/ReactFC'
import { TDuration } from 'common/cms-types'
import { paymentStrings } from 'common/strings/payment-strings'
import Icon from 'components/Icon'
import classNames from 'classnames'
import useWindowSize from 'components/util/useWindowSize' // we need this to make JSX compile

type PriceCardType = {
  duration: TDuration
  amount: number
  isActive: boolean
  monthsFree?: number
  onClick: () => void
}

const PriceCard: ReactFC<PriceCardType> = ({
  amount,
  children,
  duration,
  isActive,
  monthsFree,
  onClick,
}) => {
  const size = useWindowSize()
  return (
    <div className='col-6 text-center'>
      <CardSelect isActive={isActive} onClick={onClick}>
        {!!monthsFree && (
          <div className={'best-proposal cursor-pointer'}>
            <p
              className={'best-proposal__text'}
              dangerouslySetInnerHTML={{
                __html:
                  monthsFree % 2 === 0
                    ? `${monthsFree} ${paymentStrings.monthsFree}`
                    : paymentStrings.mostPopular,
              }}
            />
            <Icon
              name='star'
              width={9.5}
              height={9.5}
              className={'best-proposal__icon1'}
              fill={'#ffffff'}
            />
            <Icon
              name='star'
              width={16.5}
              height={16.5}
              className={'best-proposal__icon2'}
              fill={'#ffffff'}
            />
          </div>
        )}
        <h4
          className={classNames(
            'mb-0',
            size.isMdOrLower ? 'text-semibold' : 'text-bold',
          )}
        >
          {Format.money(amount)}
        </h4>
        <h5 className={'mb-0'}>
          {duration === 'month'
            ? paymentStrings.payMonthly
            : paymentStrings.payAnnually}
        </h5>
        <p className={'text-small-m text-small-m--mobile-small mb-0'}>
          {children}
        </p>
      </CardSelect>
    </div>
  )
}

export default PriceCard
