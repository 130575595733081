import { FC } from 'react'
import Accordion, { AccordionType } from './Accordion'
import classNames from 'classnames'
import imageResize from 'project/image-proxy-web' // we need this to make JSX compile

type TitledAccordionType = AccordionType & {
  image: string
  imageClasses?: string
  imageAlt: string
  title: string
  classesForTitle?: string
  subtitle: string
  className?: string
  paddingClasses?: string
  colPadding?: string
  expandAllButtonMobileOnly?: boolean
}

const TitledAccordion: FC<TitledAccordionType> = ({
  className = 'offset-lg-3',
  classesForTitle,
  colPadding = 'px-3 ps-md-0',
  expandAllButtonMobileOnly = true,
  image,
  imageAlt,
  imageClasses,
  items,
  paddingClasses = 'pb-md-6',
  subtitle,
  title,
}) => {
  return (
    <div className='row mx-auto'>
      <div className={classNames('col-md-7', colPadding)}>
        <div
          className={classNames(
            'h-100 d-flex justify-content-center justify-content-sm-start',
            className,
          )}
        >
          <div
            className={classNames(
              'my-auto text-md-start text-center',
              paddingClasses,
            )}
          >
            <h3
              className={classNames('h2 text-semibold', classesForTitle)}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <p
              className='text-muted text-xl text-semibold'
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </div>
        </div>
      </div>
      <div className='col-md-5 px-0'>
        <img
          loading='lazy'
          src={imageResize(image)}
          alt={imageAlt}
          className={classNames('img-fluid', imageClasses)}
        />
      </div>
      <div className='d-flex justify-content-md-center px-0'>
        <div className='col-lg-9 col-12 px-lg-3 px-2'>
          <Accordion
            expandAllButtonMobileOnly={expandAllButtonMobileOnly}
            expandAllButton
            items={items}
          />
        </div>
      </div>
    </div>
  )
}

export default TitledAccordion
