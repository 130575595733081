import React, { FC, ReactNode, useEffect, useState } from 'react'
import useWindowSize from './util/useWindowSize'
import classNames from 'classnames'

type StickyBarType = {
  title: ReactNode
}

const StickyBar: FC<StickyBarType> = ({ children, title }) => {
  const size = useWindowSize()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const onScroll = () => setVisible(window.pageYOffset > 315)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      {size.isXlOrHigher && (
        <div
          className={classNames(
            'sticky-bar row bg-white py-2',
            visible ? 'sticky-bar--visible' : '',
          )}
        >
          <div
            className={
              'col-11 mx-auto d-flex justify-content-between align-items-center'
            }
          >
            <p className={'h3 mb-0 text-semibold'}>{title}</p>
            <p className={'mb-0'}>{children}</p>
          </div>
        </div>
      )}
    </>
  )
}

export default StickyBar
