import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultStrings = {
  employeeScreening: 'Employee Screening',
  employeeScreeningDescription:
    '<span class="d-block">Fit and proper background checks and</span> extensive training of all employees.',
  mailTracking: 'Mail Tracking',
  mailTrackingDescription:
    '<span class="d-block">Physical mail is sorted, bar-coded, and tracked</span> <span class="d-block">under strict security standards to ensure</span> privacy is never compromised.',
  secureDestruction: 'Secure Destruction',
  secureDestructionDescription:
    '<span class="d-block">Secure cross-shredding of confidential</span> <span class="d-block">paperwork and provision of</span> a Certificate of Destruction.',
  secureFacility: 'Secure Facility',
  secureFacilityDescription:
    '<span class="d-block">A secure facility that has 24/7 monitoring</span> and restricted access technology.',
  securityAndPrivacyBuiltIn:
    '<span class="d-block d-lg-inline">Security and privacy are</span> <span class="d-block d-lg-inline">built into everything</span> we do.',
  securityAndPrivacyBuiltInDescription:
    'At Hoxton Mix, we take the responsibility of processing mail seriously, so you can feel confident that private and confidential information will remain so.',

  yourPrivacy: 'Your Privacy',
}
export const securityAndPrivacyStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
