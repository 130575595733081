import React, { FC } from 'react'
import { securityAndPrivacyStrings } from 'common/strings/security-and-privacy-strings'
import ImageCard from './base/ImageCard'
import Icon from 'components/Icon'
import useWindowSize from 'components/util/useWindowSize'
import imageResize from 'project/image-proxy-web' // we need this to make JSX compile

type SecurityAndPrivacyCardType = {}

const SecurityAndPrivacyCard: FC<SecurityAndPrivacyCardType> = ({}) => {
  const size = useWindowSize()
  return (
    <ImageCard
      padding='lg'
      className='mt-lg-18 mt-23 mb-4 px-xxxl-14 px-xxl-22 px-xl-14'
      textAlign='center'
      verticalAlign='center'
      imageLeft={!size.isLgOrHigher}
      imageAlt={`${securityAndPrivacyStrings.yourPrivacy} icon`}
      imageClass={'security-and-privacy-image max-w-lg-sm-50'}
      image={require('public/images/plans/collect-page-plugz-padlock-desktop.png')}
      classNameForCol1={'col-lg-6 pt-lg-11 pb-lg-10 pt-3 pb-5'}
      classNameForCol2={'col-lg-6 px-lg-0 px-4 ms-lg-n5'}
    >
      {!size.isLgOrHigher && (
        <img
          loading='lazy'
          className='img-fluid mb-3 envelope-icon'
          width={84}
          height={84}
          alt={'Envelope Icon'}
          src={imageResize(
            require('public/images/icons/homepage-vo-envelope-icon.svg'),
          )}
        />
      )}

      {size.isLgOrHigher && (
        <div className={'icon-box d-flex bg-pink2'}>
          <Icon
            fill={'white'}
            name='padlock'
            width={30}
            height={39}
            className={'d-block m-auto'}
          />
        </div>
      )}

      <p className='plan-text color-pink2 my-lg-4 mt-2 mb-1'>
        {securityAndPrivacyStrings.yourPrivacy}
      </p>
      <h2
        className='text-bold h3 h3--small mb-3'
        dangerouslySetInnerHTML={{
          __html: `${securityAndPrivacyStrings.securityAndPrivacyBuiltIn}`,
        }}
      />
      <p
        className='lead-large-desktop text-semibold'
        dangerouslySetInnerHTML={{
          __html: `${securityAndPrivacyStrings.securityAndPrivacyBuiltInDescription}`,
        }}
      />
    </ImageCard>
  )
}

export default SecurityAndPrivacyCard
