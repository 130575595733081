import React, { FC } from 'react'
import TitledAccordion from 'components/TitledAccordion'
import { planDetailStrings } from 'common/strings/plan-detail-strings'
import { ColorType } from 'common/types/color-type'
import { TPlanDetail } from 'common/cms-types'
import { getPlanFeaturesImage } from 'project/getPlanImages'
import Strings from 'project/localisation'
import classNames from 'classnames'
import { PlanSlug } from 'common/planSlugs'

type PlanKeyFeaturesSectionType = {
  title: string
  color: ColorType
  plan: TPlanDetail
  imageClasses?: string
}

const PlanKeyFeaturesSection: FC<PlanKeyFeaturesSectionType> = ({
  color,
  imageClasses,
  plan,
  title,
}) => {
  const hasForward = plan.slug === PlanSlug.FORWARD
  const hasScan = plan.slug === PlanSlug.SCAN_LITE
  const hasCollect = plan.slug === PlanSlug.COLLECT
  const hasScanPro = plan.slug === PlanSlug.SCAN_PRO
  return (
    <TitledAccordion
      items={[
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.safeCancellingDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.safeCancelling,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.topQualityPostCodeDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.topQualityPostCode,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  planDetailStrings.companiesHouseRegisteredAddressDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.companiesHouseRegisteredAddress,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.noHandlingCostsDescription,
              }}
            ></div>
          ),
          enabled: hasForward && (!hasScan || !hasCollect || !hasScanPro),
          title: planDetailStrings.noHandlingCosts,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.freePostHandlingDescription,
              }}
            ></div>
          ),
          enabled: !hasForward && (hasScan || hasCollect || hasScanPro),
          title: planDetailStrings.freePostHandling,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.legalAddressForHMRCDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.legalAddressForHMRC,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.receiveYourBusinessLettersDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.receiveYourBusinessLetters,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.rentMeetingRoomsDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.rentMeetingRooms,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.averageSetupTimeDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.averageSetupTime,
        },
        {
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: planDetailStrings.emailAlertsDescription,
              }}
            ></div>
          ),
          title: planDetailStrings.emailAlerts,
        },
      ].filter((v) => v.enabled !== false)}
      image={getPlanFeaturesImage(plan.slug)}
      imageClasses={classNames(imageClasses, 'mx-auto d-block')}
      imageAlt={`${Strings[plan.planSummary.name]} ${Strings.keyFeatures} icon`}
      title={`<span class="d-block color-${color}">
          ${Strings[plan.planSummary.name]}
          </span>
         <span class="d-block text-semibold">
          ${title}
          </span>`}
      classesForTitle={''}
      subtitle={`${Strings.keyFeatures}`}
    />
  )
}

export default PlanKeyFeaturesSection
