import { ReactFC } from 'common/types/ReactFC'
import ImageCard from './base/ImageCard'
import { mailHandlingStrings } from 'common/strings/mail-handling-strings'
import classNames from 'classnames' // we need this to make JSX compile

export type PhysicalCopyCardType = {
  className?: string
}

const PhysicalCopyCard: ReactFC<PhysicalCopyCardType> = ({
  children,
  className,
}) => {
  return (
    <ImageCard
      className={classNames('mb-lg-30 mb-8 position-relative', className)}
      textAlign='center'
      imageAlt={`${mailHandlingStrings.needAPhysicalCopy} icon`}
      image={require('public/images/banking/scan-lite-page-physical-copy-desktop.png')}
      imageClass={
        'position-lg-absolute top-0 bottom-0 end-0 max-w-xxxl-md-50 m-auto'
      }
      classNameForCol1={
        'col-lg-6 px-lg-10 px-5 pt-lg-10 pt-7 pb-lg-8 pb-4 text-center'
      }
      classNameForCol2={'col-lg-6 px-0 pb-lg-0 pb-7'}
      padding={'0'}
    >
      <h3 className='h2 mb-3'>{mailHandlingStrings.needAPhysicalCopy}</h3>
      <div
        className={'lead'}
        dangerouslySetInnerHTML={{
          __html: mailHandlingStrings.needAPhysicalCopyDescription,
        }}
      />
    </ImageCard>
  )
}

export default PhysicalCopyCard
