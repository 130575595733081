import React, { FC } from 'react'
import { securityAndPrivacyStrings } from 'common/strings/security-and-privacy-strings'
import SecurityAndPrivacyCard from 'components/cards/SecurityAndPrivacyCard'
import IconFeatureCard from 'components/cards/base/IconFeatureCard'

type PrivateOfficeFeaturesSectionType = {}

const PrivateOfficeFeaturesSection: FC<
  PrivateOfficeFeaturesSectionType
> = ({}) => {
  return (
    <>
      <SecurityAndPrivacyCard />
      <div className={'row'}>
        <IconFeatureCard
          iconImageSource={require('public/images/icons/secure-facility-icon.svg')}
          title={securityAndPrivacyStrings.secureFacility}
          content={securityAndPrivacyStrings.secureFacilityDescription}
          className={'py-lg-8 pt-8 pb-9'}
        />
        <IconFeatureCard
          iconImageSource={require('public/images/icons/employee-screening-icon.svg')}
          title={securityAndPrivacyStrings.employeeScreening}
          content={securityAndPrivacyStrings.employeeScreeningDescription}
          className={'py-lg-8 pt-8 pb-9'}
        />
        <IconFeatureCard
          iconImageSource={require('public/images/icons/mail-tracking-icon.svg')}
          title={securityAndPrivacyStrings.mailTracking}
          content={securityAndPrivacyStrings.mailTrackingDescription}
          className={'pt-lg-6 pb-lg-7 pt-8 pb-9'}
        />
        <IconFeatureCard
          iconImageSource={require('public/images/icons/secure-destruction-icon.svg')}
          title={securityAndPrivacyStrings.secureDestruction}
          content={securityAndPrivacyStrings.secureDestructionDescription}
          className={'pt-lg-6 pb-lg-7 pt-8 pb-9'}
        />
      </div>
    </>
  )
}

export default PrivateOfficeFeaturesSection
