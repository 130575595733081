import * as React from 'react'
import { ReactNode } from 'react'
import classNames from 'classnames'
import { ReactFC } from 'common/types/ReactFC'
import Strings from 'project/localisation'
import { ColorType } from 'common/types/color-type'
import Heading from 'components/Heading'
import { Format } from 'common/utils'
import { planDetailStrings } from 'common/strings/plan-detail-strings'
import StarRating from 'components/StarRating'
import { reviewsStrings } from 'common/strings/reviews-strings'
import useWindowSize from 'components/util/useWindowSize'
import HeadingWithSpan from 'components/HeadingWithSpan'
import { TDuration } from 'common/cms-types'
import imageResize from 'project/image-proxy-web'

type PlanHeroType = {
  title: ReactNode
  subtitle: ReactNode
  color: ColorType
  theme?: 'light' | 'dark'
  image: string
  price: number
  className?: string
  planName?: string
  duration: TDuration
}

const PlanHero: ReactFC<PlanHeroType> = ({
  className,
  color,
  duration,
  image,
  planName,
  price,
  subtitle,
  theme = 'dark',
  title,
}) => {
  const size = useWindowSize()
  return (
    <>
      <div className={'position-xl-sticky'}>
        <p className='text-medium text-medium--lt2 text-medium--mobile-small mb-0 color-pink5'>
          {planDetailStrings.setupInTenMinutes}
        </p>
        <Heading
          size={1}
          className={classNames(
            `color-${color} h1--mobile-small my-md-3 my-0`,
            size.isMdOrLower ? 'text-semibold' : '',
          )}
        >
          {title}
        </Heading>
        <p className={'mt-sm-0 mt-n2 mb-sm-3 mb-2'}>
          <span className='h4 h4--mobile-small text-semibold'>
            {Format.money(price)}
          </span>
          &nbsp;
          <span className='text-small-m text-small-m--mobile-small text-semibold'>
            {duration == 'month'
              ? `${Strings.perMonthPlusVAT}. ${planDetailStrings.flexibleMonthToMonthTerm}`
              : `${Strings.perYearPlusVAT}. ${planDetailStrings.twelveMonthsForPriceOfTenMonths}`}
          </span>
        </p>
        <div
          className={classNames(
            'hero hero--plan rounded-box position-relative pt-md-7 pt-3 px-md-4 px-2',
            `bg-${color}`,
            className,
            `hero--${planName}-plan`,
            {
              'color-white': theme === 'light',
            },
          )}
        >
          <div className='container'>
            <HeadingWithSpan size={3} className='h3'>
              {subtitle}
            </HeadingWithSpan>
            {image && (
              <img
                loading='eager'
                className={classNames(
                  'hero__image',
                  `hero__image--${planName}-plan`,
                )}
                alt={`${title} ${Strings.logo}`}
                src={imageResize(image)}
              />
            )}
            <div className='reviews mb-md-5 mb-3'>
              {!size.isSmOrLower && (
                <>
                  <div className={`d-flex`}>
                    <StarRating
                      theme='light'
                      width={18}
                      height={17}
                      value={5}
                    />
                  </div>
                  <div className={`d-flex mt-2 mb-1`}>
                    <img
                      loading='eager'
                      src={imageResize(
                        require('public/images/ratings/reviews-io-white-small.svg'),
                      )}
                      alt={'Reviews IO logo'}
                      className='img-fluid d-block'
                    />
                  </div>
                  <div className={`color-white text-small-m`}>
                    {4.9} {reviewsStrings.average} | {91}{' '}
                    {reviewsStrings.reviews}
                  </div>
                </>
              )}
              {size.isSmOrLower && (
                <>
                  <div className={`d-flex`}>
                    <StarRating
                      theme='light'
                      width={10}
                      height={10}
                      value={5}
                    />
                  </div>
                  <div className={`d-flex mt-0 mb-1`}>
                    <img
                      loading='eager'
                      src={imageResize(
                        require('public/images/ratings/reviews-io-white-small.svg'),
                      )}
                      width={66}
                      alt={'Reviews IO logo'}
                      className='img-fluid d-block'
                    />
                  </div>
                  <div className={`color-white text-xs`}>
                    <div>
                      {4.9} {reviewsStrings.average}
                    </div>{' '}
                    <div>
                      {91} {reviewsStrings.reviews}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlanHero
