import { ReactFC } from 'common/types/ReactFC'
import classNames from 'classnames'
import { GradientType } from 'common/types/color-type'
import Card from './Card'
import React from 'react'
import useWindowSize from 'components/util/useWindowSize'
import imageResize from 'project/image-proxy-web'

type ColorCardType = {
  theme: GradientType
  hasHmSymbol?: boolean
  hasBgHmSymbol?: boolean
  hmSymbolSize?: 'small' | 'medium' | 'large'
  className?: string
  col?: string
  margin?: string
}

const ColorCard: ReactFC<ColorCardType> = ({
  children,
  className = 'px-xl-13 px-7 py-md-6 py-4',
  col = 'col-xl-6',
  hasBgHmSymbol = false,
  hasHmSymbol = false,
  hmSymbolSize = 'small',
  margin = 'mb-lg-0 mb-4',
  theme,
}) => {
  const size = useWindowSize()
  return (
    <div className={classNames(col, margin)}>
      <Card
        theme={theme}
        symbol={hasBgHmSymbol}
        className={classNames(`color-white`, className, {
          'plug-card': hasBgHmSymbol,
        })}
      >
        {hasHmSymbol && (
          <img
            loading='lazy'
            alt={`Hm symbol icon`}
            className={`img-fluid hm-symbol--${hmSymbolSize}`}
            src={imageResize(
              require('public/images/icons/homepage-hm-symbol-desktop.svg'),
            )}
          />
        )}
        {children}
      </Card>
    </div>
  )
}

export default ColorCard
