import { PropsWithChildren } from 'react'
import Card, { CardType } from './Card'
import { ReactFC } from 'common/types/ReactFC'
import { ColorType } from 'common/types/color-type'
import KeyPoint from 'components/KeyPoint'
import classNames from 'classnames'

type KeyPointsCardType = CardType & {
  color: ColorType
  items: {
    title: string
    body: string
  }[]
  paragraphClass?: string
}

const KeyPointsCard: ReactFC<PropsWithChildren<KeyPointsCardType>> = ({
  color,
  items,
  paragraphClass = 'mb-xxxl-5 mb-lg-3 mb-6',
  ...rest
}) => {
  return (
    <div className='col-xl-6'>
      <Card {...rest} padding={rest.padding || 'lg'}>
        {items.map((item, i) => (
          <KeyPoint
            key={i}
            title={item.title}
            body={item.body}
            color={color}
            paragraphClass={classNames(
              'text-medium--lt2',
              i == items.length - 1 ? 'mb-0' : paragraphClass,
              ' pe-3 pe-lg-0',
            )}
          />
        ))}
      </Card>
    </div>
  )
}

export default KeyPointsCard
