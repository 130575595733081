import { FC } from 'react'
import { TDuration } from 'common/cms-types'
import Strings from 'project/localisation' // we need this to make JSX compile

type DurationType = {
  children: TDuration
}

const Duration: FC<DurationType> = ({ children }) => {
  switch (children) {
    case 'month': {
      return <>{`${Strings.perMonth}`}</>
    }
    case 'year': {
      return <>{`${Strings.perYear}`}</>
    }
    default: {
      return null
    }
  }
}

export default Duration
